import './App.css';
import { Outlet } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <body className="App-header">
        <Outlet />
      </body>
    </div>
  );
}

export default App;
