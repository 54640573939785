import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewSale from './views/NewSale';
import Login from './views/Login';
import Home from './views/Home';
import Landing from './views/Landing';
import { AuthProvider } from 'react-auth-kit';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider authType={'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Landing />} />
            <Route path="login" element={<Login />} />
            <Route path="home" element={<Home />} />
            <Route path="/sale" element={<NewSale />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
