import React from 'react';
import { useState, useEffect } from "react";
import 'react-phone-number-input/style.css'
import { Phone } from 'react-telephone';
import ax from "../utils/axios";


const Login = () => {

    const [phoneNr, setPhoneNr] = useState();
    const [country, setCountry] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getGeoInfo();
    }, [])

    const getGeoInfo = async () => {
        const response = await ax.get('https://ipapi.co/json/');
        let data = response.data;
        console.log(data, data.country.toLowerCase());
        setCountry(data.country.toLowerCase());
    };

    const login = async () => {
        setIsLoading(true);
        try {
            const res = await ax.get(`/login?phone=${phoneNr}&cc=${country}`);
            console.log(res);
        } catch (ex) {
        }
        setIsLoading(false);
    }

    return (
        <div className="mt-20">
            {country && <Phone defaultCountry={country} className="relative rounded text-white shadow-sm bg-grey-800 -space-y-px">
                <Phone.Country
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="block bg-transparent p-2 outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300 rounded-none rounded-t border border-grey-500/50 text-base" />

                <Phone.Number
                    placeholder="6 12 34 56 78"
                    value={phoneNr}
                    onChange={(e) => setPhoneNr(e.target.value)}
                    className="block bg-transparent px-3 py-2 rounded-none rounded-b border outline-none w-full border-grey-500/50 text-base placeholder:text-grey-500/40 focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
                />
            </Phone>}

            <button
                type="button"
                onClick={login}
                className="mt-10 flex w-full justify-center rounded-full bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Send Login Link
            </button>
            {isLoading && <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 class="text-center text-white text-xl font-semibold">Check your wapp...</h2>
            </div>}
        </div>
    );
}

export default Login;