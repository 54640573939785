import { useState, useEffect } from "react";
import ax from "../utils/axios";
import { Link } from "react-router-dom";
import { Phone } from 'react-telephone';

const NewSale = () => {

    const [step, setStep] = useState(0)
    const [progress, setProgress] = useState();
    const [error, setError] = useState();
    const [preview, setPreview] = useState();
    const [blurred, setBlurred] = useState();
    const [price, setPrice] = useState();
    const [assetData, setAssetData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [priceError, setPriceError] = useState();
    const [phoneNr, setPhoneNr] = useState();
    const [country, setCountry] = useState();
    const [phoneError, setPhoneError] = useState();
    const [countryName, setCountryName] = useState('');
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        getGeoInfo();
    }, [])

    const getGeoInfo = async () => {
        const response = await ax.get('https://ipapi.co/json/')
        let data = response.data;
        setCountryName(data.country_name);
        setCountryCode(data.country_calling_code);
        setCountry(data.country.toLowerCase());
        console.log(countryCode, countryName, country);
    };

    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    const handleImage = (e) => {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        //Clear the error message
        setError("");

        formData.append("upload_preset", "ml_default");

        ax.post(
            `https://api.cloudinary.com/v1_1/dt2q9dqzj/image/upload`,
            formData
        )
            .then((response) => {
                console.log(response);
                setAssetData(response.data);
                setStep(1);
                setIsLoading(false);
                setPreview(response.data.secure_url);
                setBlurred(response.data.secure_url.replace("/upload", "/upload/h_300,w_250/e_blur:600/"))
            })
            .catch((error) => {
                console.log(error);
            });

    };


    const handleVideo = (e) => {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        //Clear the error message
        setError("");

        formData.append("upload_preset", "ml_default");

        ax.post(
            `https://api.cloudinary.com/v1_1/dt2q9dqzj/video/upload`,
            formData
        )
            .then((response) => {
                console.log(response);
                setAssetData(response.data);
                setStep(1);
                setIsLoading(false);
                setPreview(response.data.secure_url.substr(0, response.data.secure_url.lastIndexOf(".")) + ".jpg");
                setBlurred(response.data.secure_url.replace("/upload", "/upload/h_300,w_250/e_blur:600/"));
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const publishContent = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_BACKEND}/cta/${assetData.asset_id}`);
    }

    const setPricing = async () => {
        if (price) {
            if (parseFloat(price) < 1) {
                setPriceError("The minimum price is 1 USD");
            } else {
                setStep(2);
                setPriceError(null);
            }

        } else {
            setPriceError("You must set the price to continue");
        }

    }

    const setPhone = async () => {
        if (country && phoneNr) {
            assetData.price = price;
            assetData.country = country;
            assetData.phoneNr = phoneNr;
            setIsLoading(true);
            try {
                const { data } = await ax.get("/create-asset", { params: assetData });
                console.log(data);
            } catch (error) {

            }
            setStep(3)
            setIsLoading(false);
        } else {
            setPhoneError("Please select country and phone number");
        }

    }

    return (
        <div className="mt-10">
            {step === 0 && <>
                <div className="w-full px-5 pt-6 pb-20">


                    <div
                        className="rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <label for="dropzone-img" class="">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="mx-auto h-16 w-16 text-white-400">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                            </svg>
                            <span className="mt-2 block text-white-400">Sell Photo 💰</span>
                            <p class="text-xs text-gray-500 dark:text-gray-400">(max. 10MB)</p>
                            <input id="dropzone-img" type="file" class="hidden" onChange={handleImage} />
                        </label>
                    </div>
                </div>

                <div className="w-full px-5 pb-10">
                    <button
                        type="button"
                        className="rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <label for="dropzone-video" class="">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="mx-auto h-16 w-16 text-white-400">
                                <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                            </svg>

                            <span className="mt-2 block text-white-400">Sell Video 💰</span>
                            <p class="text-xs text-gray-500 dark:text-gray-400">(max. 100MB)</p>
                            <input id="dropzone-video" type="file" class="hidden" onChange={handleVideo} />
                        </label>
                    </button>
                </div>

                <Link
                    to="/login"
                    className="mt-10 mb-10 py-4 px-4 flex w-full justify-center rounded-full bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    View My Sales
                </Link>
            </>}

            {
                step === 1 && <div>
                    <h2 className='text-2xl font-semibold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight'>Price your content</h2>
                    <div class="flex flex-row mt-10">
                        <div className="font-semibold leading-6 text-gray-500">
                            Before payment
                            <img
                                className="h-36 w-36 rounded-md m-4"
                                src={blurred}
                            />
                        </div>
                        <div className="font-semibold leading-6 text-gray-500">
                            After payment
                            <img
                                className="h-36 w-36 rounded-md m-4"
                                src={preview}
                                alt=""
                            />
                        </div>
                    </div>

                    <label htmlFor="email" className="font-semibold leading-6 text-gray-500 mb-4 mt-8 flex justify-items-start block text-sm font-medium leading-6 text-white-900">
                        Sales Price in US$ (min 1$)
                    </label>
                    <div>
                        <input
                            type="number"
                            name="price"
                            id="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Enter amount in USD"
                        />
                    </div>
                    <div className="flex justify-between">
                        <button
                            type="button"
                            onClick={() => setPrice(5)}
                            className="mt-4 mb-4 rounded-full bg-indigo-600 py-1 px-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            $5
                        </button>
                        <button
                            type="button"
                            onClick={() => setPrice(10)}
                            className="mt-4 mb-4 rounded-full bg-indigo-600 py-1 px-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            $10
                        </button>
                        <button
                            type="button"
                            onClick={() => setPrice(25)}
                            className="mt-4 mb-4 rounded-full bg-indigo-600 py-1 px-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            $25
                        </button>
                        <button
                            type="button"
                            onClick={() => setPrice(50)}
                            className="mt-4 mb-4 rounded-full bg-indigo-600 py-1 px-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            $50
                        </button>
                    </div>

                    <button
                        type="button"
                        onClick={setPricing}
                        className="mt-10 py-4 px-4 flex w-full justify-center rounded-full bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Continue
                    </button>
                   
                    <div class="mt-5">
                    <div className="">By continuing you accept that we charge a <br/> 20% fee for the services provided. You can <br/> request payouts at any time.</div>
</div>

                    {priceError && <div className="mt-5">{priceError}</div>}
                </div>
            }
            {
                step === 2 && <>
                    <div className='text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight'>Almost ready ...</div>
                    <div className="m-10 font-semibold leading-6 text-gray-500 ">
                        Enter your WhatsApp to access your sales later
                    </div>
                    <div className="">
                        <div className="px-4 py-5 grid mt-10 justify-items-center ">
                            <Phone defaultCountry={country} className="relative rounded text-white shadow-sm bg-grey-800 -space-y-px">
                                <Phone.Country
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    id="country-code"
                                    className="block bg-transparent p-2 outline-none focus:ring-1 focus:ring-blue-300 focus:border-blue-300 rounded-none rounded-t border border-grey-500/50 text-base" />

                                <Phone.Number
                                    placeholder="6 12 34 56 78"
                                    value={phoneNr}
                                    onChange={(e) => setPhoneNr(e.target.value)}
                                    className="block bg-transparent px-3 py-2 rounded-none rounded-b border outline-none w-full border-grey-500/50 text-base placeholder:text-grey-500/40 focus:ring-1 focus:ring-blue-300 focus:border-blue-300"
                                />
                            </Phone>
                        </div>
                    </div>
                    <div className="px-10 py-5">
                        <button
                            type="button"
                            onClick={setPhone}
                            className="w-full py-4 mt-5 justify-center py-2 px-3 rounded-full bg-indigo-600 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Continue
                        </button>
                    </div>
                    {phoneError && <div className="mt-5">{phoneError}</div>}
                </>
            }

            {
                step === 3 && <>
                    <h2 className='text-2xl font-bold leading-7 text-white w-80'>You're all set!<br /><br />Share the preview link to your content and get paid by anyone</h2>
                    <div className="">
                        <div className="px-4 py-5 grid justify-items-center ">
                            <div className="px-4 py-5 sm:p-6">
                                <img
                                    className="h-36 w-36 rounded-md"
                                    src={blurred}
                                />
                            </div>
                            <p className="cpx-4 py-4 sm:px-6 text-white break-words w-60">
                                {`${process.env.REACT_APP_BACKEND}/cta/${assetData.asset_id}`}
                            </p>
                            <div className="px-5 font-semibold leading-6 text-gray-500 ">
                            </div>
                        </div>
                    </div>
                    <div>
                        <a
                            onClick={publishContent}
                            className="py-4 grid mt-5 juspy-4 mt-5 justify-center py-2 px-3 rounded-full bg-indigo-600 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Copy link
                        </a>
                        <a
                            href="https://wa.me/971502579574"
                            target="_blank"
                            className="py-4 grid mt-5 mb-20 juspy-4 mt-5 justify-center py-2 px-3 rounded-full bg-gray-600 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >
                            Contact Us
                        </a>
                    </div>
                </>
            }

            {isLoading && <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 class="text-center text-white text-xl font-semibold">Uploading...</h2>
            </div>}
        </div>
    )
}

export default NewSale;