import React from 'react';
import { Link } from "react-router-dom";

const Landing = () => {

    return (
        <div className="mt-10 w-full px-10">
            <div class="flex flex-row justify-center">
                <div className="font-semibold leading-6 text-gray-500">
                    <img
                        className="h-24 rounded-md m-4"
                        src={"./logo_trans_w.png"}
                    />
                </div>
            </div>
            <p className='mb-10 mt-5'>Get paid for content sent over WhatsApp or via Instagram DMs</p>
            <p>
                1. Upload & blur content<br />
                2. Set unlock price<br />
                3. Share content link with recipient on
            </p>
            <div className='flex justify-center items-center mt-6'>
            <svg className='mr-4' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="40px" height="40px"><radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5" /><stop offset=".328" stop-color="#ff543f" /><stop offset=".348" stop-color="#fc5245" /><stop offset=".504" stop-color="#e64771" /><stop offset=".643" stop-color="#d53e91" /><stop offset=".761" stop-color="#cc39a4" /><stop offset=".841" stop-color="#c837ab" /></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9" /><stop offset=".999" stop-color="#4168c9" stop-opacity="0" /></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z" /><circle cx="31.5" cy="16.5" r="1.5" fill="#fff" /><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z" /></svg>
            <svg width="32px" height="32px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <defs>
                    <radialGradient cx="19.2474387%" cy="99.4651948%" fx="19.2474387%" fy="99.4651948%" r="108.959789%" id="messengerGradient-1">
                        <stop stop-color="#0099FF" offset="0%"></stop>
                        <stop stop-color="#A033FF" offset="60.9753877%"></stop>
                        <stop stop-color="#FF5280" offset="93.482299%"></stop>
                        <stop stop-color="#FF7061" offset="100%"></stop>
                    </radialGradient>
                </defs>
                <g>
                    <path d="M128,-1.8189894e-14 C55.89376,-1.8189894e-14 0,52.81792 0,124.16 C0,161.476608 15.29344,193.722368 40.199168,215.99488 C42.28992,217.86624 43.552,220.48768 43.63776,223.29344 L44.334848,246.06208 C44.557568,253.3248 52.059392,258.05056 58.705152,255.1168 L84.11136,243.90144 C86.265088,242.95168 88.6784,242.77504 90.94784,243.39968 C102.62272,246.60992 115.048448,248.32 128,248.32 C200.10624,248.32 256,195.50208 256,124.16 C256,52.81792 200.10624,-1.8189894e-14 128,-1.8189894e-14 Z" fill="url(#messengerGradient-1)"></path>
                    <path d="M51.136512,160.470016 L88.736512,100.816896 C94.71744,91.326976 107.52512,88.964096 116.4992,95.694336 L146.404352,118.123264 C149.14816,120.181504 152.923392,120.170496 155.65568,118.096896 L196.044032,87.444736 C201.43488,83.353856 208.471552,89.805056 204.864,95.529984 L167.264,155.183616 C161.28256,164.673024 148.47488,167.036416 139.501312,160.305664 L109.595392,137.876224 C106.85184,135.818496 103.076352,135.829504 100.34432,137.903104 L59.955712,168.555264 C54.56512,172.645888 47.528192,166.194688 51.136512,160.470016 Z" fill="#FFFFFF"></path>
                </g>
            </svg>
            <svg className='ml-5' width="32px" height="32px" viewBox="0 0 256 258" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <defs>
                    <linearGradient x1="49.9999726%" y1="100.000225%" x2="49.9999726%" y2="4.85578831e-05%" id="whatsappLinearGradient-1">
                        <stop stop-color="#1FAF38" offset="0%"></stop>
                        <stop stop-color="#60D669" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="50.0000414%" y1="100.000108%" x2="50.0000414%" y2="-0.000129944756%" id="whatsappLinearGradient-2">
                        <stop stop-color="#F9F9F9" offset="0%"></stop>
                        <stop stop-color="#FFFFFF" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g>
                    <path d="M5.46329987,127.456258 C5.45681909,149.133181 11.1210232,170.299417 21.890788,188.954998 L21.890788,188.954998 L4.43285541,252.697385 L69.6645263,235.593304 C87.6357369,245.388359 107.871333,250.558727 128.464668,250.563912 L128.464668,250.563912 L128.519107,250.563912 C196.334018,250.563912 251.537325,195.380046 251.565851,127.55347 L251.565851,127.55347 C251.578802,94.6855323 238.790922,63.7799755 215.557316,40.5282231 L215.557316,40.5282231 C192.327598,17.2777669 161.43241,4.46655548 128.513922,4.45229776 L128.513922,4.45229776 C60.6912344,4.45229776 5.49181531,59.6322745 5.46329987,127.456258" fill="url(#whatsappLinearGradient-1)"></path>
                    <path d="M1.07062531,127.416078 C1.06284837,149.873286 6.93054909,171.796478 18.0839762,191.11958 L18.0839762,191.11958 L0,257.147091 L67.5712334,239.429927 C86.1892261,249.581425 107.15067,254.933255 128.481518,254.941032 L128.481518,254.941032 L128.535957,254.941032 C198.783754,254.941032 255.970181,197.772751 256,127.518474 L256,127.518474 C256.011658,93.4697375 242.763642,61.4533745 218.700495,37.3681934 L218.700495,37.3681934 C194.63346,13.2856047 162.632651,0.0142577221 128.535957,0 L128.535957,0 C58.2751986,0 1.09914076,57.1592078 1.07062531,127.416078 L1.07062531,127.416078 Z M41.3098055,187.792346 L38.7874848,183.787222 C28.1810358,166.922929 22.5829356,147.435216 22.5907045,127.423855 L22.5907045,127.423855 C22.6140434,69.0294095 70.1376234,21.5213834 128.576138,21.5213834 L128.576138,21.5213834 C156.875124,21.5330488 183.472256,32.5646372 203.47584,52.5811829 L203.47584,52.5811829 C223.479424,72.5990247 234.486386,99.2091187 234.478613,127.510697 L234.478613,127.510697 C234.452686,185.906438 186.92781,233.420945 128.535957,233.420945 L128.535957,233.420945 L128.49448,233.420945 C109.481159,233.410576 90.8346512,228.305015 74.571775,218.656426 L74.571775,218.656426 L70.7014515,216.360933 L30.6035524,226.874058 L41.3098055,187.792346 Z" fill="url(#whatsappLinearGradient-2)"></path>
                    <path d="M96.6782434,74.1484502 C94.2920192,68.8445776 91.781364,68.7382928 89.5117939,68.6449695 C87.6544015,68.565904 85.5300009,68.5710189 83.4081926,68.5710189 C81.2837921,68.5710189 77.8334233,69.369521 74.9157749,72.5554738 C71.9955342,75.7427228 63.7675324,83.4470773 63.7675324,99.1163139 C63.7675324,114.786847 75.181487,129.928547 76.7718711,132.05554 C78.3648475,134.178645 98.8052363,167.362845 131.176746,180.128691 C158.081068,190.737732 163.556033,188.627589 169.395218,188.096165 C175.234404,187.566037 188.238742,180.394403 190.891975,172.957057 C193.546503,165.521006 193.546503,159.146509 192.750663,157.815356 C191.954823,156.488091 189.830423,155.690955 186.645766,154.099275 C183.459813,152.506298 167.802242,144.800648 164.883297,143.737799 C161.963057,142.676247 159.839952,142.146119 157.715552,145.334664 C155.592447,148.519321 149.492734,155.690955 147.634046,157.815356 C145.776654,159.943645 143.917965,160.209357 140.733309,158.61638 C137.547356,157.018219 127.289573,153.658582 115.121255,142.809751 C105.652831,134.367884 99.2614834,123.942897 97.4027949,120.754351 C95.5454026,117.569695 97.204483,115.84451 98.8013479,114.256719 C100.232305,112.82965 101.987301,110.538045 103.580277,108.679357 C105.169365,106.819372 105.699493,105.492108 106.761045,103.367707 C107.823894,101.242011 107.292469,99.382026 106.496629,97.7890496 C105.699493,96.1960732 99.5103455,80.4464749 96.6782434,74.1484502" fill="#FFFFFF"></path>
                </g>
            </svg>
            </div>
            <Link
                to="/sale"
                className="mt-20 py-4 px-4 flex w-full justify-center rounded-full bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Upload
            </Link>
            <Link
                    to="/login"
                    className="mt-10 mb-10 py-4 px-4 flex w-full justify-center rounded-full bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    View My Sales
                </Link>
        </div>
    );
}

export default Landing;