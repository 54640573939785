import React, { useEffect } from 'react';
import { useState } from "react";
import 'react-phone-number-input/style.css'
import { Phone } from 'react-telephone';
import ax from "../utils/axios";
import { useSignIn } from 'react-auth-kit';

const Home = () => {

    const signIn = useSignIn();
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let auth = params.get("auth");
        login(auth);
    }, [])

    const login = async (auth) => {
        console.log('fetch user info');
        setIsLoading(true);
        const res = await ax.get(`/auth?auth=${auth}`);
        console.log(res.data);
        setUser(res.data);
        signIn({
            expiresIn: 9000000000,
            token: res.data,
            tokenType: "Bearer",
            authState: { hash: auth }
        });
        setIsLoading(false);
        return res.data;
    }

    return (
        <div className='p-5 min-w-full'>
            <h2 className='mt-2 text-2xl font-bold leading-7 text-white-900 sm:truncate sm:text-3xl sm:tracking-tight'>My Account</h2>


            <div className='mt-10 mb-2 text-sm font-semibold leading-6 text-gray-500 text-left'>Account {user?.phone}</div>
            <div className="bg-gray-800 rounded-lg">
                <div className="px-4 py-5 ">
                    <div className="sm:flex sm:items-start sm:justify-between">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-white-900">Available for payout</h3>
                            <div className="mt-2 text-sm text-gray-500">
                                <p>
                                    {new Date().toLocaleString()}
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 text-2xl font-bold leading-7 text-white-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            ${user?.payout}
                        </div>
                        {isLoading && <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                            <h2 class="text-center text-white text-xl font-semibold">Fetching history...</h2>
                        </div>}
                    </div>
                    <a
                        href="https://wa.me/971502579574"
                        target="_blank"
                        className="py-4 grid mt-5 mb-20 juspy-4 mt-5 justify-center py-2 px-3 rounded-full bg-indigo-600 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Request Payout
                    </a>
                </div>
            </div>


            <div>
                <h3 className="mt-10 mb-2 text-sm font-semibold leading-6 text-gray-500 text-left">Earnings</h3>
            </div>
            <div className="bg-gray-800 rounded-lg">
                <div className="px-4 py-5 ">
                    <table className="min-w-full divide-y divide-gray-700">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 sm:pl-0">
                                    Content
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm text-gray-300">
                                    Date
                                </th>
                                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-300">
                                    Earned
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-800">
                            {user && user.earnings.map((sale) => (
                                <tr key={sale.dateTime}>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-white text-left"><div className='inline-flex'><img src={sale.asset} height={20} width={20}></img><span className='ml-5'>{sale.id.substring(0, 5) + "..."}</span></div></td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-white text-left">{new Date(parseInt(sale.dateTime) * 1000).toLocaleDateString("en-US")}</td>
                                    <td className="whitespace-nowrap py-4 px-3 text-sm text-white text-left">USD {parseInt(sale.price) / 100}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>



                </div>
            </div>
        </div>
    );
}

export default Home;